<style>
.input-readonly>div>.vs-inputx {
	background-color: #e6e6e6;
}
</style>
<template>
    <vx-card title="Customer Invoice Exchange Detail">
      <div class="flex flex-col gap-6">
        <div class="flex flex-col gap-3">
          <label>Sold To</label>
          <div class="vx-col sm:w-5/6 w-full whitespace-no-wrap">
            <!-- <multiselect
              class="selectExample"
              v-model="selectedSoldTo"
              :options="
                !customerOptions || customerOptions.length == 0
                  ? []
                  : customerOptions
              "
              :multiple="true"
              :allow-empty="true"
              :group-select="false"
              :max-height="160"
              :limit="3"
              placeholder=" Type to search"
              track-by="id"
              label="code"
              :disabled="false"
              @search-change="onShipToChange"
            >
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">{{ props.option.code }}</span>
                </div>
              </template>
            </multiselect> -->

            <vs-input
              class="w-full input-readonly"
              name="Ref Code"
              v-model="soldTo"
              readonly
            />
          </div>
        </div>
  
        <div class="flex flex-col gap-3">
          <label>Customer(s)</label>
          <div class="vx-col sm:w-5/6 w-full whitespace-no-wrap">
            <customer-display :soldToData="selectedSoldTo" />
          </div>
        </div>
        <div class="vx-col sm:w-5/6 w-full whitespace-no-wrap">
          <vs-divider />
        </div>
        <div class="flex flex-col gap-6">
          <schedule
            v-for="(sch, index) in schedulesDatas"
            :key="`${index}-${listKey}`"
            :index="index"
            @removeSchedule="removeSchedule"
            @updateScheduleData="updateScheduleData"
            :isFirstSchedule="schedulesDatas.length === 1"
            :scheduleData="sch"
          />
        </div>
        <div class="vx-col sm:w-5/6 w-full whitespace-no-wrap">
          <vs-button class="mt-4" type="border" icon="add" @click="addSchedule"
            >Add Schedule</vs-button
          >
        </div>
        <div class="vx-col sm:w-5/6 w-full whitespace-no-wrap">
          <vs-divider />
        </div>

        <div class="vx-col sm:w-5/6 w-full whitespace-no-wrap">
          <label class="vs-input--label">Active</label>
          <vs-checkbox :checked="active" v-model="active">
              </vs-checkbox>
        </div>

        <div class="vx-col sm:w-5/6 w-full whitespace-no-wrap">
          <vs-divider />
        </div>
  
        <div
          class="vx-col sm:w-5/6 w-full whitespace-no-wrap flex flex-row gap-3"
        >
          <vs-button
            class="mt-4"
            :to="{
              path: '/finance/customer-invoice-exchange',
            }"
            color="danger"
            >Back</vs-button
          >
          <vs-button class="mt-4" @click="createInvoiceExchangeSchedule"
            >Update</vs-button
          >
        </div>
      </div>
    </vx-card>
  </template>
  
  <script>
  import customerDisplay from "./customer-display.vue";
  import schedule from "./schedule.vue";
  export default {
    data() {
      return {
        soldTo: "",
        customerOptions: [],
        selectedSoldTo: [],
        customerName: "",
        defaultSchedule: {
          id: 1,
          type: "date",
          week_of_month: 0,
          value: 1,
        },
        schedulesDatas: [],
        listKey: "1",
        active: true,
      };
    },
    components: {
      schedule: schedule,
      "customer-display": customerDisplay,
    },
    mounted() {
      // this.customerOptions = this.options;
      this.getCustomerAddress();
      this.getData();
      // this.schedulesDatas.push(this.defaultSchedule);
    },
    watch: {},
    methods: {
      createInvoiceExchangeSchedule() {
        if (Object.keys(this.selectedSoldTo) === 0) {
          this.$vs.notify({
            title: "Error",
            text: "Please select Sold To",
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
          });
          return;
        }
  
        if (this.selectedSoldTo.length === 0) {
          this.$vs.notify({
            title: "Error",
            text: "Please select Sold To",
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
          });
          return;
        }
  
        const customer_ids = [];
        const sold_to_ids = [];
        this.selectedSoldTo.forEach((item) => {
          customer_ids.push(item.customer_id);
          sold_to_ids.push(item.id);
        });
  
        console.log(this.active, "harusnya true");
        const data = {
          customer_ids,
          sold_to_ids,
          schedules: this.schedulesDatas,
          id: parseInt(this.$route.params.ids, 10),
          active: this.active ? "Active" : "Inactive",
        };
  
        this.$vs.loading();
        this.$http
          .put("api/v1/customer-invoice-exchange/update", data)
          .then((r) => {
            this.$vs.loading.close();
            if (r.code < 300) {
              this.$vs.notify({
                title: "Success",
                text: "Schedule has been created",
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
              });
              // this.resetState();
              this.$router.push({
                path: "/finance/customer-invoice-exchange",
              });
            } else {
              this.$vs.notify({
                title: "Error",
                text: r.message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-alert-circle",
                position: "top-right",
              });
            }
          })
          .catch((e) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: e.message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
            });
          });
      },
      getCustomerAddress(search = "") {
        this.$vs.loading();
        this.$http
          .get("api/v1/master/customer-address/filter", {
            params: {
              search,
            },
          })
          .then((r) => {
            this.customerOptions = r.data;
            this.$vs.loading.close();
          })
          .catch((e) => {
            this.$vs.loading.close();
            console.log(e);
          });
      },
      addSchedule() {
        this.schedulesDatas.push({
          // id,
          type: "date",
          week_of_month: 0,
          value: 1,
        });
        this.listKey = Math.random().toString();
      },
      removeSchedule(index) {
        this.schedulesDatas.splice(index, 1);
        this.listKey = Math.random().toString();
      },
      updateScheduleData(data, index) {
        this.schedulesDatas[index] = data;
      },
      onShipToChange(val) {
        console.log(val, "Ship to change");
        if (val.length < 3 && val.length > 0) {
          return;
        }
  
        this.getCustomerAddress(val);
      },
      resetState() {
        this.selectedSoldTo = {};
        this.customerName = "";
        this.schedulesDatas = [];
        // this.schedulesDatas.push(this.defaultSchedule);
      },
      querySoldTo({ code, address }) {
        if (!code || !address) {
          return "Select Sold To";
        }
        return `(${code}), ${address}`;
      },
      onSearchChange(val) {
        console.log(val);
      },
      getData() {
        this.$http.get('/api/v1/customer-invoice-exchange/' + this.$route.params.ids).then((r) => {
          let resp = r.data.data;
          console.log("ini jalan", resp.schedules);
          const soldTo = {
            address: resp.sold_to_address,
            code: resp.customer_code,
            customer_id : resp.customer_id,
            id: resp.sold_to_id,
            name: resp.customer_name,
          }
          this.schedulesDatas = []
          this.schedulesDatas = [...this.schedulesDatas, ...resp.schedules]
          this.soldTo = resp.customer_code
          this.active = resp.active == "Active" ? true : false
          this.selectedSoldTo.push(soldTo)
        }).catch((e) => {
          
        })
      }
    },
  };
  </script>
  